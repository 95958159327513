import React, { Component } from 'react';
import Title from '../../../components/Title';
import { withRouter } from 'react-router-dom';
import Loading from '../../../components/Loading';
import TablePayments from '../../../components/table/TablePayments';
import { SelectorItemObject } from '../../../components/form/item/SelectorItem';
import PrintButton from '../../../components/button/PrintButton';
import { getRequest, responseManagment } from '../../../functions/ajax';
import { setLog } from '../../../action_creators/logActionCreators';
import {
	setAccountCurrentExercice,
	setAccountExercices,
	setAccountPayments,
} from '../../../action_creators/accountActionCreators';
import { connect } from 'react-redux';
import { printTable } from '../../../functions/print';

class MyPaymentHistory extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.goBack = this.goBack.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
	}

	UNSAFE_componentWillMount() {
		const account = this.props.account;
		if (!account.exercices.length) {
			this.getPayments();
		} else if (
			account.current_exercice !== account.history.payments.exercice
			|| !account.payments.length
		) {
			this.updatePayments();
		}
		// Do nothing if exercices have not change and there are already payments amounts
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.account.current_exercice !== this.props.account.current_exercice) {
			this.updatePayments();
		}
	}

	async getPayments() {
		if (!this.props.account.transactions.length) {
			this.setState({ is_loading: true });
			const response = await getRequest(
				`/paiement-locataire?activity=${this.props.leases.current.activity}`,
				this.props.user.token
			);

			if (this.responseManagment(response)) {
				this.props.setAccountPayments(response.paiements);
				this.props.setAccountExercices(response.exercices)
			}

			if (this._isMounted) {
				this.setState({ is_loading: false });
			}
		}
	}

	async updatePayments() {
		this.setState({ is_loading: true });
		const account = this.props.account;
		const response = await getRequest(
			`/paiement-locataire?activity=${this.props.leases.current.activity}
			&start=${account.exercices[account.current_exercice].start}
			&end=${account.exercices[account.current_exercice].end}`,
			this.props.user.token
		);

		if (this.responseManagment(response)) {
			this.props.setAccountPayments(response.paiements);
		} else {
			this.props.setAccountPayments([]);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleExercice(evt) {
		this.props.setAccountCurrentExercice(evt.target.value);
	}

	handlePrint() {
		printTable(
			'table-payments',
			'historique-des-paiements.pdf',
			'Historique des paiements',
			this.props.leases.current
		);
	}

	goBack() {
		this.props.history.replace(`/lease/${this.props.match.params.id}/payments/`);
	}

	render() {
		return (
			<>
				<Title title="Mes paiements" />
				<div className="payment-history">
					<div className="page-title">
						<button className="btn back-btn" onClick={this.goBack}>
							<img
								src="/images/pictogrammes/back-arrow-blue.svg"
								alt="retour"
							/>
						</button>
						<h2>Tous mes paiements</h2>
						<PrintButton print={this.handlePrint} />
					</div>
					<div className="standfirst toggle-plus-select">
						{this.props.account.exercices.length ? (
							<SelectorItemObject
								id="select-account-exerice"
								label="Période"
								current={this.props.account.current_exercice}
								options={this.props.account.exercices}
								callback={this.handleExercice}
							/>
						) : null}
					</div>
					{this.state.is_loading === false ? (
						this.props.account.payments.length ? (
							<div className="scrollable-table">
								<TablePayments
									cols={['DATE', 'INTITULÉ', 'MONTANT', 'MOYEN DE PAIEMENT']}
									table={this.props.account.payments}
									pieces={true}
								/>
							</div>
						) : (
							<p>
								Il n'y a aucune opération sur votre compte à cette
								période.
							</p>
						)
					) : (
						<Loading />
					)}
				</div>
			</>
		);
	}
}
const mapStateToProps = state => {
	return {
		leases: state.leases,
		user: state.user,
		account: state.account,
	};
};
const mapDispatchToProps = {
	setAccountExercices,
	setAccountCurrentExercice,
	setAccountPayments,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyPaymentHistory));
