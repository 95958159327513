//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Title extends Component {
	render() {
		const { title } = this.props;

		return (
			<div className="hero">
				<div className="hero-title">
					<h1>{title}</h1>
					{this.props.children}
				</div>
				<div className="hero-menu">
					{Object.keys(this.props.user).length && (
						<h1 className="user-menu-title">
							{this.props.user.first_name
								? `${this.props.user.first_name} ${this.props.user.name[0]}.`
								: this.props.user.name}
						</h1>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		leases: state.leases,
	};
};

export default withRouter(connect(mapStateToProps)(Title));
