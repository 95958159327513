import React, { Component } from 'react';
import Title from '../../components/Title';
import Loading from '../../components/Loading';
import { getRequest, responseManagment } from '../../functions/ajax';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	setAccountExercices,
	setAccountPayments,
	setGlobalAccountAmounts,
	setMandate,
	setShortPayments,
} from '../../action_creators/accountActionCreators';
import { Link } from 'react-router-dom';
import { formatNumbers } from '../../functions';
import TablePayments from '../../components/table/TablePayments';
import TableMandate from '../../components/table/TableMandate';

class MyPayments extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
			is_loading_mandate: false,
			is_half_loading: false,
			currentSolde: null,
		};

		this.responseManagment = responseManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		const account = this.props.account;
		if (account?.global_amounts?.solde == null) {
			this.getSolde();
		}
		this.getPayments();
		this.getMandate();
	}

	componentDidMount() {
		this._isMounted = true;
		this.getSolde();
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getSolde() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/compte-locataire-graph?activity=${this.props.leases.current.activity}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setAccountExercices(response.exercices);
			this.props.setGlobalAccountAmounts({
				solde: response.solde,
			});
			this.setState({ currentSolde: response.solde });
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async getPayments() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/paiement-locataire?activity=${this.props.leases.current.activity}`,
			this.props.user.token
		);

		if (this.responseManagment(response)) {
			this.props.setShortPayments(response.paiements);
			this.props.setAccountExercices(response.exercices);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async getMandate() {
		if (!this.props.account.mandate.length) {
			this.setState({ is_loading_mandate: true });
			const response = await getRequest(
				`/debit-mandate?activity=${this.props.leases.current.activity}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setMandate(response.debit_mandate);
			}

			if (this._isMounted) {
				this.setState({ is_loading_mandate: false });
			}
		}
	}

	render() {
		const { account } = this.props;
		return (
			<>
				<Title title="Mes paiements" />
				<div className="payments">
					<div className="card solde">
						<div className="card-header">
							<h2>Mon solde à la date du jour</h2>
						</div>
						<div className="card-body">
							<div className="solde-amount">
								<h3>
									{this.state.is_loading ? (
										<Loading />
									) : (
										formatNumbers(
											this.props.account?.global_amounts?.solde || 0
										) + ' €'
									)}
								</h3>
							</div>
							<p>
								Si votre solde est négatif, cela veut dire qu’il vous
								reste à payer cette somme. S’il est positif, vous avez de
								l’avance sur vos règlements.
							</p>
						</div>
					</div>
					<div className="card history">
						<div className="card-header">
							<h2>Historique des paiements</h2>
						</div>
						<div className="card-body">
							{this.state.is_loading === false ? (
								this.props.account.short_payments.length ? (
									<div className="table-wrapper">
										<TablePayments
											cols={[
												'DATE',
												'INTITULÉ',
												'MONTANT',
												'MOYEN DE PAIEMENT',
											]}
											table={this.props.account.short_payments}
										/>
									</div>
								) : (
									<p>
										Il n'y a aucune opération sur votre compte à cette
										période.
									</p>
								)
							) : (
								<Loading />
							)}
							<div className="btn-wrapper">
								<Link
									className="btn main-btn"
									to={`/lease/${this.props.match.params.id}/payments/history`}
								>
									Voir tous mes paiements
								</Link>
							</div>
						</div>
					</div>
					<h1>Choisissez votre moyen de paiement</h1>
					<div className="payments-methods">
						<div className="card">
							<div className="card-header">
								{Object.keys(account.mandate).length ? (
									<h2>Prélèvement automatique - mandat</h2>
								) : (
									<h2>Prélèvement automatique</h2>
								)}
							</div>
							<div className="card-body">
								{this.state.is_loading_mandate === false ? (
									Object.keys(account.mandate).length ? (
										<div className="table-wrapper">
											<TableMandate
												table={account.mandate}
												cols={[
													'CRÉANCIER',
													'DÉBITEUR',
													'DATE DEBUT',
													'DOCUMENT',
												]}
											/>
										</div>
									) : (
										<p>
											Plus d'oublis ou de retards : votre paiement
											est effectué automatiquement pour chaque
											échéance à venir.
										</p>
									)
								) : (
									<Loading />
								)}

								<div className="btn-wrapper">
									<Link
										className="btn main-btn"
										to={`/lease/${this.props.match.params.id}/payments/direct-debit`}
									>
										{Object.keys(account.mandate).length
											? 'Renouveler mon RIB'
											: 'Souscrire'}
									</Link>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header">
								<h2>Virement</h2>
							</div>
							<div className="card-body">
								<p>
									Une solution simple de paiement par virement que vous
									initiez via votre application bancaire.
								</p>
								<div className="btn-wrapper">
									<button className="btn disabled-btn" disabled>
										Bientôt disponible
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases,
		user: state.user,
		account: state.account,
	};
};

const mapDispacthToProps = {
	setAccountExercices,
	setGlobalAccountAmounts,
	setAccountPayments,
	setShortPayments,
	setMandate,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyPayments));
