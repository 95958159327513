// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
// component
import DashBoard from '../lease/DashBoard';
import Incidents from '../lease/Incidents';
import MyReminders from '../lease/account/MyReminders';
import MyTenantAccount from '../lease/account/MyTenantAccount';
import MyFiles from '../lease/MyFiles';
import Contact from '../lease/contact/Contact';
//actions
import { setCurrentLease } from '../../action_creators/leasesActionCreators';
import clearLeaseActions from '../../constants/clearLeaseActionList';
import InsurancePage from '../lease/InsurancePage';
import HeaterPage from '../lease/HeaterPage';
import ReportIncident from '../lease/ReportIncident';
import MyLease from '../lease/MyLease';
import EPayment from '../lease/account/EPayment';
import DirectDebit from '../lease/account/DirectDebit';
import PaymentsDashboard from '../lease/PaymentsDashboard';
import PaymentsList from '../lease/account/PaymentsList';
import CallsDashboard from '../lease/account/CallsDashboard';
import CallsList from '../lease/account/CallsList';

class LeaseRouter extends Component {
	UNSAFE_componentWillMount() {
		// Clear store from other lease datas
		Object.keys(clearLeaseActions).forEach(action => {
			this.props[action]();
		});
		if (Object.keys(this.props.leases.list).length) {
			const current_lease_id = this.props.match.params.id;
			if (current_lease_id) {
				const current_lease = this.props.leases.list[current_lease_id];
				if (current_lease) {
					this.props.setCurrentLease(current_lease);
					return;
				}
			}
		}
		this.props.history.replace('/');
	}

	render() {
		if (this.props.leases.current) {
			return (
				<Switch>
					{/* DashBoard */}
					<Route exact path="/lease/:id/dashboard">
						<DashBoard />
					</Route>
					{/* Incidents */}
					<Route exact path="/lease/:id/incidents/report">
						<ReportIncident />
					</Route>
					<Route exact path="/lease/:id/incidents">
						<Incidents />
					</Route>
					<Route exact path="/lease/:id/incidents/:page">
						<Incidents />
					</Route>
					{/* Properties */}
					<Route exact path="/lease/:id/lease">
						<MyLease />
					</Route>
					<Route exact path="/lease/:id/lease/renew-insurance">
						<InsurancePage />
					</Route>
					<Route exact path="/lease/:id/lease/renew-heater">
						<HeaterPage />
					</Route>
					{/* Account */}
					<Route exact path="/lease/:id/account">
						<CallsDashboard />
					</Route>
					<Route exact path="/lease/:id/account/calls">
						<CallsList />
					</Route>
					<Route exact path="/lease/:id/account/reminders">
						<MyReminders />
					</Route>
					{/* Tenant account */}
					<Route exact path="/lease/:id/tenant-account">
						<MyTenantAccount />
					</Route>
					{/* Payments */}
					<Route exact path="/lease/:id/payments">
						<PaymentsDashboard />
					</Route>
					<Route exact path="/lease/:id/payments/history">
						<PaymentsList />
					</Route>
					{/* <Route exact path="/lease/:id/payments/e-payment">
						<EPayment />
					</Route> */}
					<Route exact path="/lease/:id/payments/direct-debit">
						<DirectDebit />
					</Route>
					{/* Files */}
					<Route exact path="/lease/:id/files">
						<MyFiles />
					</Route>
					{/* Contact */}
					<Route exact path="/lease/:id/contact">
						<Contact />
					</Route>
					{/* Redirection */}
					<Route path="/lease/:id">
						<Redirect to={`/lease/${this.props.match.params.id}/dashboard`} />
					</Route>
				</Switch>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases,
	};
};
const mapDispatchToProps = Object.assign({ setCurrentLease }, clearLeaseActions);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeaseRouter));
