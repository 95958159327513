import React, { Component } from 'react';
import Title from '../../components/Title';
import TableLease from '../../components/table/TableLease';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Loading from '../../components/Loading';
import { getRequest, responseManagment } from '../../functions/ajax';
import { updateCurrentLease } from '../../action_creators/leasesActionCreators';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TableRent from '../../components/table/TableRent';

class MyLease extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading_lease: false,
		};

		this.responseManagment = responseManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.leases.current.start) {
			this.getLeaseDetails();
		}
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getLeaseDetails() {
		this.setState({ is_loading_lease: true });
		const response = await getRequest(
			`/leases/${this.props.leases.current.id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.updateCurrentLease(response.lease);
		}

		if (this._isMounted) {
			this.setState({ is_loading_lease: false });
		}
	}

	render() {
		return (
			<>
				<Title title="Mes biens" />
				<div className="lease">
					<div className="card my-lease">
						<div className="card-header">
							<h2>Mon Bail</h2>
						</div>
						<div className="card-body">
							{this.state.is_loading_lease === false ? (
								this.props.leases.current.start ? (
									<>
										<div className="table-wrapper">
											<TableLease
												table={this.props.leases.current}
											/>
										</div>
										<div className="btn-wrapper">
											<Link
												to={`/lease/${this.props.match.params.id}/lease/renew-insurance`}
												className="btn main-btn"
											>
												Renouveler l'assurance
											</Link>
											<Link
												to={`/lease/${this.props.match.params.id}/lease/renew-heater`}
												className="btn main-btn"
											>
												Renouveler contrats entretien
											</Link>
										</div>
									</>
								) : (
									<p>Bail incomplet.</p>
								)
							) : (
								<Loading />
							)}
						</div>
					</div>
					<div className="card rent">
						<div className="card-header">
							<h2>Mon loyer</h2>
						</div>
						<div className="card-body">
							{this.state.is_loading_lease === false ? (
								<TableRent table={this.props.leases.current} />
							) : (
								<Loading />
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases,
		user: state.user,
	};
};

const mapDispatchToProps = {
	updateCurrentLease,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyLease));
