// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const mb_to_b = 1048576;

class FileItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dragging: false,
		};

		this.handleDrag = this.handleDrag.bind(this);
		this.handleDrop = this.handleDrop.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
	}

	handleFiles(evt) {
		this.sendFiles(evt.target.files);
	}

	sendFiles(files) {
		let send_files = [];
		if (files.length) {
			for (let [i, file] of Object.entries(files)) {
				if (i > this.props.nb_max - 1) {
					this.props.setLog({
						type: 'error',
						message: `Vous essayez d'importer plus ${
							this.props.nb_max > 1
								? `de ${this.props.nb_max} ${this.props.type}`
								: `d'une ${this.props.type}`
						}.`,
					});
					break;
				}
				if (
					(this.props.type === 'image'
						&& file.type !== 'image/jpeg'
						&& file.type !== 'image/png'
						&& file.type !== 'image/gif')
					|| (this.props.type === 'pdf' && file.type !== 'application/pdf')
				) {
					this.props.setLog({
						type: 'error',
						message: 'Le format du fichier est incorrecte.',
					});
					continue;
				}
				if (file.size > this.props.max_Mo * mb_to_b) {
					this.props.setLog({
						type: 'error',
						message: `Le fichier est trop volumineux, il fait plus de ${this.props.max_Mo}Mo.`,
					});
					continue;
				}
				send_files.push(file);
			}
		}
		this.props.callback(send_files);
	}

	handleDrag(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		if (evt.type === 'dragenter' || evt.type === 'dragover') {
			evt.dataTransfer.dropEffect = 'copy';
			this.setState({ dragging: true });
		} else if (evt.type === 'dragleave') {
			this.setState({ dragging: false });
		}
	}

	handleDrop(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.sendFiles(evt.dataTransfer.files);
		this.setState({ dragging: false });
	}

	render() {
		return (
			<div className="form-item-file-wrapper">
				<div
					className={`form-item-file${this.state.dragging ? ' dragging' : ''}`}
					onDragEnter={this.handleDrag}
					onDragLeave={this.handleDrag}
					onDragOver={this.handleDrag}
					onDrop={this.handleDrop}
				>
					<span className="upload-file">{this.props.label}</span>
					<div className="d-flex flex-column">
						<span className="medium">
							Types de fichiers autorisés&nbsp;:{' '}
							{this.props.type === 'image'
								? 'gif\u00A0; jpg\u00A0; jpeg\u00A0; png'
								: 'PDF'}
						</span>
						<span className="medium">
							Taille maximale : {this.props.max_Mo}Mo
						</span>
					</div>
					{this.props.nb_max > 1 && (
						<span>{this.props.nb_max} fichiers maximum</span>
					)}
					<div className="form-group">
						<label
							className={
								'btn outline-btn blue'
								+ (this.props.checked ? ' checked' : '')
							}
							htmlFor={this.props.name}
						>
							{this.props.label_btn}
						</label>
						<input
							type="hidden"
							name="MAX_FILE_SIZE"
							value={this.props.max_Mo * mb_to_b}
						/>
						<input
							type="file"
							accept={
								this.props.type === 'image'
									? 'image/gif,image/jpeg,image/png'
									: 'application/pdf'
							}
							id={this.props.name}
							name={this.props.name}
							className="input-file"
							onChange={this.handleFiles}
							multiple={this.props.nb_max > 1 ? 'multiple' : false}
						/>
					</div>
				</div>
			</div>
		);
	}
}

FileItem.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	label_btn: PropTypes.string.isRequired,
	btn_color: PropTypes.string,
	title: PropTypes.string,
	type: PropTypes.oneOf(['image', 'pdf']),
	nb_max: PropTypes.number.isRequired,
	max_Mo: PropTypes.number.isRequired,
	required: PropTypes.bool,
	checked: PropTypes.any,
	callback: PropTypes.func.isRequired,
	setLog: PropTypes.func.isRequired,
};

FileItem.defaultProps = {
	required: false,
	name: 'file',
};

export default FileItem;
