//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// component
import TableFile from '../../components/table/TableFile';
// actions
import { setLog } from '../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../functions/ajax';
import { setFolders, updateFolder } from '../../action_creators/filesActionCreators';

// const
import Title from '../../components/Title';
import FileToggleButton from '../../components/button/FileToggleButton';
import GridFile from '../../components/table/GridFile';

class MyFiles extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
			folder_index: null,
			layout: 'table',
		};

		this.responseManagment = responseManagment.bind(this);
		this.handleFolder = this.handleFolder.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getFolders();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getFolders() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/gestionnaire-fichiers?activity=${this.props.leases.current.activity}`,
			this.props.user.token
		);

		if (this.responseManagment(response)) {
			const { folders, files } = response;
			this.props.setFolders({ folders, files });
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async getFolder(index) {
		const folder = this.props.main_folder.folders[index];
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/gestionnaire-fichiers?`
				+ `activity=${this.props.leases.current.activity}`
				+ (folder.id ? `&folder=${folder.id}` : ''),
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			const { folders, files } = response;
			this.props.updateFolder(index, { folders, files });
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleFolder(i) {
		const current_folder = this.props.main_folder.folders[i];
		if (i === this.state.folder_index || i === null) {
			this.setState({ folder_index: null });
			return;
		}
		this.setState({ folder_index: i });
		if (current_folder.folders === undefined) {
			this.getFolder(i);
		}
	}

	handleToggle(value) {
		this.setState({
			layout: value,
		});
	}

	render() {
		const { main_folder } = this.props;
		const { folder_index, is_loading, layout } = this.state;
		const current_folder = main_folder.folders[folder_index];
		return (
			<>
				<Title title="Mes fichiers" />
				<div className="my-files">
					<div className="breadcrumb-container">
						<FolderBreadCrumb
							main_folder={main_folder}
							folder_index={folder_index}
							setFolderIndex={this.handleFolder}
						/>
						<FileToggleButton layout={layout} onClick={this.handleToggle} />
					</div>
					{layout === 'table' ? (
						<div className="scrollable-table">
							<TableFile
								handleFolder={this.handleFolder}
								folder={main_folder}
								folder_index={folder_index}
								is_loading={is_loading}
							/>
						</div>
					) : (
						<GridFile
							handleFolder={this.handleFolder}
							folder={folder_index != null ? current_folder : main_folder}
							is_loading={is_loading}
						/>
					)}
				</div>
			</>
		);
	}
}

const FolderBreadCrumb = ({ main_folder, folder_index, setFolderIndex }) => (
	<div className="breadcrumb">
		<div className="file-path">
			<span onClick={e => setFolderIndex(null)} className="breadcrumb-anchor">
				{main_folder.label}&nbsp;
			</span>
			/&nbsp;
			{folder_index !== null && (
				<>
					<span className="breadcrumb-anchor">
						{main_folder.folders[folder_index].label}
						&nbsp;
					</span>
					/&nbsp;
				</>
			)}
		</div>
	</div>
);

const mapStateToProps = state => {
	return {
		leases: state.leases,
		user: state.user,
		main_folder: state.main_folder,
	};
};
const mapDispatchToProps = {
	setLog,
	setFolders,
	updateFolder,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyFiles));
