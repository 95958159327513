//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableCalls from '../../../components/table/TableCalls';
import { SelectorItemObject } from '../../../components/form/item/SelectorItem';
import Loading from '../../../components/Loading';
//action
import {
	setCalls,
	setCallsExercices,
	setCallsCurrentExercice,
} from '../../../action_creators/accountActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import Title from '../../../components/Title';

class MyCalls extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = { is_loading: false };

		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.calls.list.length) {
			this.getCalls();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.calls.current_exercice !== this.props.calls.current_exercice) {
			this.updateCalls();
		}
	}

	async getCalls() {
		const { leases } = this.props;
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/calls?`
				+ `activity=${leases.current.activity}`
				+ `&lease=${leases.current.id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setCallsExercices(response.exercices);
			this.props.setCalls(response.calls);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateCalls() {
		const { calls, leases } = this.props;
		const exercice = calls.exercices[calls.current_exercice];
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/calls?`
				+ `activity=${leases.current.activity}`
				+ `&lease=${leases.current.id}`
				+ `&start=${exercice.start}`
				+ `&end=${exercice.end}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setCallsExercices(response.exercices);
			this.props.setCalls(response.calls);
		} else {
			this.props.setCalls({});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	goBack() {
		this.props.history.replace(`/lease/${this.props.match.params.id}/account/`);
	}

	handleExercice(evt) {
		this.props.setCallsCurrentExercice(evt.target.value);
	}

	render() {
		const { calls, leases } = this.props;
		return (
			<>
				<Title title="Mes loyers" />
				<div className="page-title">
					<button className="btn back-btn" onClick={this.goBack}>
						<img
							src="/images/pictogrammes/back-arrow-blue.svg"
							alt="retour"
						/>
					</button>
					<h2>Tous mes loyers</h2>
				</div>
				{calls.exercices.length > 0 && (
					<div className="standfirst toggle-plus-select">
						<SelectorItemObject
							id="select-calls-exerice"
							label="Période"
							current={calls.current_exercice}
							options={calls.exercices}
							callback={this.handleExercice}
						/>
					</div>
				)}
				{this.state.is_loading === false ? (
					calls.list.length ? (
						<div className="scrollable-table">
							<TableCalls
								cols={['DATE', 'MONTANT', 'RESTANT', 'DOCUMENTS']}
								table={calls.list}
								activity={leases.current.activity}
							/>
						</div>
					) : (
						<p>Aucun loyer à cette période.</p>
					)
				) : (
					<Loading />
				)}
			</>
		);
	}
}

const mapStateToProps = state => ({
	leases: state.leases,
	user: state.user,
	calls: state.calls,
});

const mapDispacthToProps = {
	setCalls,
	setCallsExercices,
	setCallsCurrentExercice,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyCalls));
