// module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
// component
import Aside from '../../components/Aside';
import MyLeases from '../MyLeases';
import MyInfo from '../MyInfo';
import LeaseRouter from './LeaseRouter';
import Help from '../lease/contact/Help';
import LegalNotice from '../LegalNotice';
import ErrorBoundary from '../../components/ErrorBoundary';

class Page extends Component {
	render() {
		return (
			<Fragment>
				<Aside />
				<div className="page">
					<main>
						<ErrorBoundary>
							<Switch>
								<Route exact path="/">
									<MyLeases />
								</Route>
								<Route path="/info">
									<MyInfo />
								</Route>
								<Route path="/lease/:id">
									<LeaseRouter />
								</Route>
								<Route exact path="/help">
									<Help />
								</Route>
								<Route exact path="/help/legal-notice">
									<LegalNotice />
								</Route>
								{/* Redirection */}
								<Route path="/">
									<Redirect to={`/`} />
								</Route>
							</Switch>
						</ErrorBoundary>
					</main>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		leases: state.leases,
	};
};

export default withRouter(connect(mapStateToProps)(Page));
