//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableReminders from '../../../components/table/TableReminders';
import { SelectorItemObject } from '../../../components/form/item/SelectorItem';
import Loading from '../../../components/Loading';
//action
import {
	setReminders,
	setRemindersExercices,
	setRemindersCurrentExercice,
} from '../../../action_creators/accountActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import Title from '../../../components/Title';

class MyReminders extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = { is_loading: false };

		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.reminders.list.length) {
			this.getReminders();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.reminders.current_exercice !== this.props.reminders.current_exercice
		) {
			this.updateReminders();
		}
	}

	async getReminders() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/relance?activity=${this.props.leases.current.activity}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setRemindersExercices(response.exercices);
			this.props.setReminders(response.relances);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateReminders() {
		const { reminders, leases } = this.props;
		const exercice = reminders.exercices[reminders.current_exercice];
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/relance?`
				+ `activity=${leases.current.activity}`
				+ `&start=${exercice.start}`
				+ `&end=${exercice.end}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setRemindersExercices(response.exercices);
			this.props.setReminders(response.relances);
		} else {
			this.props.setReminders({});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	goBack() {
		this.props.history.replace(`/lease/${this.props.match.params.id}/account/`);
	}

	handleExercice(evt) {
		this.props.setRemindersCurrentExercice(evt.target.value);
	}

	render() {
		const { reminders, leases } = this.props;
		return (
			<>
				<Title title="Mes loyers" />
				<div className="my-reminders">
					<div className="page-title">
						<button className="btn back-btn" onClick={this.goBack}>
							<img
								src="/images/pictogrammes/back-arrow-blue.svg"
								alt="retour"
							/>
						</button>
						<h2>Mes relances</h2>
					</div>
					{reminders.exercices.length > 0 && (
						<div className="standfirst toggle-plus-select">
							<SelectorItemObject
								id="select-reminders-exerice"
								label="Période"
								current={reminders.current_exercice}
								options={reminders.exercices}
								callback={this.handleExercice}
							/>
						</div>
					)}
					{this.state.is_loading === false ? (
						reminders.list.length ? (
							<div className="scrollable-table">
								<TableReminders
									cols={[
										'DATE',
										'MONTANT',
										'RESTANT',
										'TYPE',
										'DOCUMENTS',
									]}
									table={reminders.list}
									activity={leases.current.activity}
								/>
							</div>
						) : (
							<p>Auncune relance à cette période.</p>
						)
					) : (
						<Loading />
					)}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	leases: state.leases,
	user: state.user,
	reminders: state.reminders,
});

const mapDispacthToProps = {
	setReminders,
	setRemindersExercices,
	setRemindersCurrentExercice,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyReminders));
