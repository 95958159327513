// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// component
import Notification from '../../components/Notification';
import Loading from '../../components/Loading';
import TableAdmin from '../../components/table/TableAdmin';
//actions
import { addNotifications } from '../../action_creators/notificationsActionCreators';
import { setLog } from '../../action_creators/logActionCreators';
// functions
import {
	getRequest,
	responseManagment,
	responseBlobManagment,
} from '../../functions/ajax';
import { updateCurrentLease } from '../../action_creators/leasesActionCreators';
import { setAdmin } from '../../action_creators/adminActionCreators';
import Title from '../../components/Title';
import {
	setIncidents,
	setNbIncidents,
	setNbPagesIncidents,
} from '../../action_creators/incidentsActionCreators';
import TableIncidents from '../../components/table/TableIncidents';
import { NavLink } from 'react-router-dom';
import {
	setAccountExercices,
	setGlobalAccountAmounts,
} from '../../action_creators/accountActionCreators';
import { formatNumbers } from '../../functions';

class DashBoard extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_half_loading: false,
			is_loading_notifications: false,
			is_loading_lease: false,
			is_loading_incidents: false,
			is_loading_admin: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.responseBlobManagment = responseBlobManagment.bind(this);
		this.redirectToPage = this.redirectToPage.bind(this);
	}

	UNSAFE_componentWillMount() {
		const account = this.props.account;
		if (account?.global_amounts?.solde == null) {
			this.getSolde();
		}
		if (!this.props.notifications.length) {
			this.getNotifications();
		}
		if (!Object.keys(this.props.admin).length) {
			this.getAdmin();
		}
		if (!this.props.incidents.pages[1]) {
			this.getIncidents();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	redirectToPage(page_number = 1) {
		this.props.history.replace(
			`/lease/${this.props.match.params.id}/incidents/${page_number}`
		);
	}

	async getSolde() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/compte-locataire-graph?activity=${this.props.leases.current.activity}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setAccountExercices(response.exercices);
			this.props.setGlobalAccountAmounts({
				solde: response.solde,
			});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async getNotifications() {
		this.setState({ is_loading_notifications: true });
		const response = await getRequest(
			`/notification?activity=${this.props.leases.current.activity}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.addNotifications(response.notifications);
		}

		if (this._isMounted) {
			this.setState({ is_loading_notifications: false });
		}
	}

	async getIncidents() {
		this.setState({ is_loading_incidents: true });
		const response = await getRequest(
			`/incident?activity=${this.props.leases.current.activity}`
				+ `&page=1`
				+ `&in_progress=true`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setIncidents(response.incidents, 1);
		}

		if (this._isMounted) {
			this.setState({ is_loading_incidents: false });
		}
	}

	async getAdmin() {
		this.setState({ is_loading_admin: true });
		const response = await getRequest(
			`/property_admin?activity=${this.props.leases.current.activity}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setAdmin(response.property_admin);
		}

		if (this._isMounted) {
			this.setState({ is_loading_admin: false });
		}
	}

	render() {
		return (
			<>
				<Title title="Tableau de bord" />
				<div className="dashboard">
					<div className="card solde">
						<div className="card-header">
							<h2>Mon solde à la date du jour</h2>
						</div>
						<div className="card-body">
							<div className="solde-amount">
								<h3>
									{this.state.is_loading ? (
										<Loading />
									) : (
										formatNumbers(
											this.props.account?.global_amounts?.solde || 0
										) + ' €'
									)}
								</h3>
							</div>
							<p>
								Si votre solde est négatif, cela veut dire qu’il vous
								reste à payer cette somme. S’il est positif, vous avez de
								l’avance sur vos règlements.
							</p>
							<NavLink
								to={`/lease/${this.props.leases.current.id}/tenant-account`}
								className="btn main-btn"
							>
								Voir mon compte
							</NavLink>
						</div>
					</div>
					<div className="card notifications">
						<div className="card-header">
							<h2>Notifications</h2>
						</div>
						<div className="card-body">
							{/* Starting by adding own App notifications */}
							<div className="notifications">
								<Notification title="Pas à pas">
									Découvrez votre accès personnalisé à votre location !
									<br />
									<span className="notification-chip" />
									Il vous suffit de suivre le&nbsp;
									<a
										href={`/pdf/MY-Guide-Locataire.pdf`}
										// eslint-disable-next-line react/jsx-no-target-blank
										target="_blank"
										rel="noreferrer"
									>
										guide
									</a>
								</Notification>
								<Notification title="Note de votre administrateur de bien">
									Bienvenue sur votre espace Locataire !
								</Notification>
								{/* Add API Notifications */}
								{this.state.is_loading_notifications === false ? (
									!!this.props.notifications.length
									&& this.props.notifications.map((notification, i) => (
										<Notification title={notification.title} key={i}>
											{notification.description}
										</Notification>
									))
								) : (
									<Loading />
								)}
							</div>
						</div>
					</div>
					<div className="card incidents">
						<div className="card-header">
							<h2>Dernier incident</h2>
						</div>
						<div className="card-body table-wrapper">
							{this.state.is_loading_incidents === false ? (
								this.props.incidents.pages[1]
								&& this.props.incidents.pages[1].length ? (
									<TableIncidents
										dashboard={true}
										cols={['Date', 'Type', 'Dernière étape']}
										table={this.props.incidents.pages[1].slice(0, 1)}
										current_page="1"
									/>
								) : (
									<p>Aucun incident n'a encore été déclaré.</p>
								)
							) : (
								<Loading />
							)}
							<div className="btn-wrapper">
								<NavLink
									to={`/lease/${this.props.match.params.id}/incidents/report`}
									className="btn main-btn"
								>
									Déclarer un incident
								</NavLink>
								<button
									className="btn secondary-btn"
									onClick={() =>
										this.redirectToPage(
											this.props.incidents.current_page + 1
										)
									}
								>
									Voir tous les incidents
								</button>
							</div>
						</div>
					</div>
					<div className="card property-admin">
						<div className="card-header">
							<h2>Administrateur de biens</h2>
						</div>
						<div className="card-body table-wrapper">
							{this.state.is_loading_admin === false ? (
								Object.keys(this.props.admin).length ? (
									<TableAdmin table={this.props.admin} />
								) : (
									<p>
										Il n'y a pas d'administrateur de bien sur ce bien.
									</p>
								)
							) : (
								<Loading />
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases,
		user: state.user,
		admin: state.admin,
		notifications: state.notifications,
		incidents: state.incidents,
		account: state.account,
	};
};

const mapDispatchToProps = {
	setAccountExercices,
	setGlobalAccountAmounts,
	addNotifications,
	updateCurrentLease,
	setIncidents,
	setNbIncidents,
	setNbPagesIncidents,
	setAdmin,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashBoard));
