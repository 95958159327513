// module
import React, { Component } from 'react';
// components
import { TableBase, colorOddRows } from './Table';
import TdAmount from './TdAmount';
// const
import { API_PDF_SRC_URL } from '../../constants/api';

class TableAccount extends Component {
	render() {
		return (
			<TableBase className="table-account" cols={this.props.cols}>
				<tbody className="reverse-color">
					{this.props.table.map((el, i) => (
						<tr key={i} className={colorOddRows(i)}>
							<td className="td-label">{el.label}</td>
							<td>{el.date}</td>
							<TdAmount value={el.credit - el.debit} />
							<TdAmount value={el.solde} />
							{this.props.pieces ? (
								<td>
									{el.files.length
										? el.files.map((file, i) => (
												<a
													key={i}
													href={API_PDF_SRC_URL + file.url}
													title={file.label}
													className="picto-table"
												>
													{file.label}
												</a>
										  ))
										: null}
								</td>
							) : null}
						</tr>
					))}
				</tbody>
			</TableBase>
		);
	}
}

export default TableAccount;
