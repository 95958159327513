//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import AsideNav from './AsideNav';
import MenuBurger from './MenuBurger';
import { Link } from 'react-router-dom';

class Aside extends Component {
	constructor(props) {
		super(props);

		this.state = {
			is_visible: false,
			app: null,
		};

		this.handleMenuBurger = this.handleMenuBurger.bind(this);
		this.handleClickOut = this.handleClickOut.bind(this);
	}

	componentDidMount() {
		this.setState({
			app: document.getElementsByClassName('app')[0],
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.is_visible !== this.state.is_visible) {
			if (this.state.is_visible) {
				this.state.app.classList.add('unscrollable');
			} else if (this.state.app.classList.contains('unscrollable')) {
				this.state.app.classList.remove('unscrollable');
			}
		}
	}

	handleClickOut(evt) {
		if (evt.target.className === 'in' || evt.target.tagName === 'A') {
			this.setState({ is_visible: false });
		}
	}

	handleMenuBurger(evt) {
		this.setState({ is_visible: !this.state.is_visible });
	}

	render() {
		const currentLease = this.props.leases.current;
		return (
			<aside
				className={this.state.is_visible ? 'in' : ''}
				onClick={this.handleClickOut}
			>
				<MenuBurger callback={this.handleMenuBurger} active={this.state.in} />
				<div className="aside-content-wrapper">
					<div className="logo-img-wrapper">
						{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
						<img
							src="/images/Dometech.png"
							className="profile-img img-fluid"
							alt="Image de profil"
						/>
						<h1>MON ESPACE LOCATAIRE</h1>
					</div>
					{this.props.leases.current && (
						<Link to="/leases" className="lease-name">
							{currentLease.label.length > 15
								? currentLease.label.substr(0, 15) + ' (...)'
								: currentLease.label}
						</Link>
					)}
					<AsideNav />
				</div>
			</aside>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		leases: state.leases,
	};
};

export default withRouter(connect(mapStateToProps)(Aside));
