// module
import React, { Component } from 'react';
// components
import { TableBase, colorOddRows } from './Table';
import { formatNumbers } from '../../functions';

class TablePayments extends Component {
	render() {
		return (
			<TableBase className="table-payment" cols={this.props.cols}>
				<tbody className="reverse-color">
					{this.props.table.map((el, i) => (
						<tr key={i} className={colorOddRows(i)}>
							<td>{el.date}</td>
							<td className="td-label">{el.label}</td>
							<td className={el.amount < 0 ? 'text-red' : undefined}>
								{formatNumbers(el.amount)}&nbsp;€
							</td>
							<td>{el.paiementMode || '-'}</td>
						</tr>
					))}
				</tbody>
			</TableBase>
		);
	}
}

export default TablePayments;
