//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableIncidents from '../../components/table/TableIncidents';
import Pagination from '../../components/Pagination';
import Loading from '../../components/Loading';
import TrippleToggleButtons from '../../components/button/TrippleToggleButtons';
//action
import {
	setIncidents,
	removeIncidents,
	setNbIncidents,
	setNbPagesIncidents,
	setIncidentsInProgress,
} from '../../action_creators/incidentsActionCreators';
import { setLog } from '../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../functions/ajax';
import Title from '../../components/Title';
import { NavLink } from 'react-router-dom';

class Incidents extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			current_page: 1,
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.redirectToPage = this.redirectToPage.bind(this);
		this.toggleInProgress = this.toggleInProgress.bind(this);
	}

	UNSAFE_componentWillMount() {
		// Check page param given by URL before doing anything
		this.checkPageParam();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		// Update incidents when page change
		if (prevProps.match.params.page !== this.props.match.params.page) {
			// Prevent infinite loading from page redirection during or after ajax request
			if (this.state.is_loading === true) {
				this.setState({ is_loading: false });
			}
			this.checkPageParam();
		}
		// Update incidents pagination when in_progress change
		if (prevProps.incidents.in_progress !== this.props.incidents.in_progress) {
			this.props.removeIncidents();
		}
		// If incidents have been removed, reload incidents
		if (
			// Necessary to prevent from infinite loop
			Object.values(prevProps.incidents.pages).length
			&& !Object.values(this.props.incidents.pages).length
		) {
			if (this.state.current_page === 1) {
				this.getIncidents();
			} else {
				// Redirect to page 1 if it's not, page changing whith no incidents implicate reloading
				this.redirectToPage();
			}
		}
	}

	checkPageParam() {
		let nb_current_page = this.props.match.params.page;

		if (nb_current_page === undefined) {
			// No page mentionned = page 1
			this.getIncidents();
		} else if (isNaN(nb_current_page) || nb_current_page < 1) {
			// Page param is invalid
			this.redirectToPage();
		} else {
			nb_current_page = parseInt(nb_current_page);
			// Check if page has really change
			if (nb_current_page !== this.state.current_page) {
				// Set new page in state
				this.setState({ current_page: nb_current_page });
			}
			// Check if there are no incidents on current page
			if (!this.props.incidents.pages[nb_current_page]) {
				// If yes, get then
				this.getIncidents(nb_current_page);
			}
		}
	}

	/**
	 * Redirect the incident page of the given number
	 * @param {number|string} page_number
	 */
	redirectToPage(page_number = 1) {
		this.props.history.replace(
			`/lease/${this.props.match.params.id}/incidents/${page_number}`
		);
	}

	async getIncidents(asked_page = 1) {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/incident?activity=${this.props.leases.current.activity}`
				+ `&page=${asked_page}`
				+ `&in_progress=${this.props.incidents.in_progress}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			// Check if asked page match with API pages, if not redirect to page 1
			if (response.current_page > asked_page) {
				return this.redirectToPage();
			} else if (response.current_page > 0 && response.current_page < asked_page) {
				return this.redirectToPage(response.current_page);
			}
			// Get incidents and pages number if they are not already stored
			if (!this.props.incidents.nb_incidents) {
				this.props.setNbIncidents(response.nb_incidents);
				this.props.setNbPagesIncidents(response.nb_pages);
			}
			this.props.setIncidents(response.incidents, response.current_page);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	toggleInProgress(in_progress) {
		if (in_progress !== this.props.incidents.in_progress) {
			this.props.setIncidentsInProgress(in_progress);
		}
	}

	render() {
		const incidents = this.props.incidents;
		return (
			<>
				<Title title="Incidents">
					<NavLink
						to={`/lease/${this.props.match.params.id}/incidents/report`}
						className="btn main-btn"
					>
						Déclarer un incident
					</NavLink>
				</Title>
				<div className="incident">
					<TrippleToggleButtons
						value={incidents.in_progress}
						toggle={this.toggleInProgress}
						label_true="En cours"
						label_false="Traités"
						label_all="Voir tout"
					/>
					{this.state.is_loading === false ? (
						incidents.pages[this.state.current_page]
						&& incidents.pages[this.state.current_page].length ? (
							<div className="scrollable-table">
								<TableIncidents
									cols={['Date', 'Type', 'Libellé', 'Options']}
									table={incidents.pages[this.state.current_page]}
									current_page={this.state.current_page}
								/>
							</div>
						) : (
							<p>Aucun incident n'a été déclaré.</p>
						)
					) : (
						<Loading />
					)}

					{incidents.nb_pages > 1 && (
						<Pagination
							page_max={incidents.nb_pages}
							callback={this.redirectToPage}
							current_page={this.state.current_page}
						/>
					)}
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases,
		user: state.user,
		incidents: state.incidents,
	};
};
const mapDispacthToProps = {
	setIncidents,
	removeIncidents,
	setNbIncidents,
	setNbPagesIncidents,
	setIncidentsInProgress,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(Incidents));
