// module
import React from 'react';
import PropTypes from 'prop-types';
// component
import Form from './Form';
import FileItem from './item/FileItem';

class FormIncident extends Form {
	constructor(props) {
		super(props);

		this.initialState = { ...this.initialState, files: [] };
		this.state = this.initialState;

		this.handleChangeFiles = this.handleChangeFiles.bind(this);
	}

	handleChangeFiles(files) {
		if (this.state.files.length > 1) {
			// There is already two images in this component
			this.props.setLog({
				type: 'error',
				message: "Veuillez retirer une image, avant d'en ajouter une nouvelle.",
			});
		} else if (this.state.files.length) {
			// There is one image
			let stated_files = this.state.files.slice();
			stated_files.push(files[0]);
			this.setState({ files: stated_files });
		} else {
			this.setState({ files });
		}
	}

	removeIncidentImage(index) {
		let nextState = { ...this.state };
		nextState.files.splice(index, 1);
		let img = document.getElementById(`incident-file-${index}`);
		URL.revokeObjectURL(img.src);
		this.setState(nextState);
	}

	render() {
		let form_items = this.buildFormItems();

		return (
			<form className="form form-incident" onSubmit={this.handleSubmit}>
				<div className="form-item-list-incident">
					<div className="fields">{form_items}</div>
					<div className="form-item-file">
						<FileItem
							title="Fichiers"
							label="Déposez des fichiers"
							label_btn="Glisser / Déposer"
							btn_color="grey"
							type="image"
							nb_max={2}
							max_Mo={10}
							callback={this.handleChangeFiles}
							setLog={log => this.props.setLog(log)}
						/>
						{!!this.state.files.length && (
							<div className="incident-files-wrapper">
								{this.state.files.map((file, i) => (
									<div key={i} className="incident-image-wrapper">
										<div
											className="incident-image-wrapper-overlay"
											onClick={evt => this.removeIncidentImage(i)}
										>
											<img
												id={`incident-file-${i}`}
												alt={`fichier-${i}`}
												src={URL.createObjectURL(file)}
											/>
										</div>
									</div>
								))}
							</div>
						)}
						<div className="incident-form-info">
							<p>
								En cas de validation, un mail est envoyé au gestionnaire.
							</p>
						</div>
					</div>
				</div>
				<div className="submit-button-wrapper">
					<button className="btn submit-button main-btn" type="submit">
						{this.props.label_btn}
					</button>
				</div>
			</form>
		);
	}
}

FormIncident.propTypes = {
	form_items: PropTypes.arrayOf(PropTypes.object).isRequired,
	callback: PropTypes.func.isRequired,
	setLog: PropTypes.func.isRequired,
};

export default FormIncident;
